// Source, inspiré de mon code en Projet Technologique

import React, { createContext, ReactNode, useContext } from "react";

interface AppContextType {

  settingApiAddress: string;
  setSettingApiAddress: React.Dispatch<React.SetStateAction<string>>;
  apiAppKey: string;
  setApiAppKey: React.Dispatch<React.SetStateAction<string>>;
  bearerToken: string;
  setBearerToken: React.Dispatch<React.SetStateAction<string>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }

  return context;
};

interface AppProviderProps {
  children: ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [settingApiAddress, setSettingApiAddress] = React.useState<string>(
    "https://148.113.204.147:3000/" // Mettre l'adresse de l'API et le port
  );

  
  const [apiAppKey, setApiAppKey] = React.useState<string>(
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmFtZSI6InN1cGVyQWRtaW5AZWR3cmRsLmNhIiwiaWF0IjoxNzI1NTQ5MzYxfQ.AVa1fiCwOZIaE65iDHKzoD0HRD9_qzVesMHpQPh0yiM"
  );

  const [bearerToken, setBearerToken] = React.useState<string>(
    ""
  );

  const contextValue = {
    settingApiAddress,
    setSettingApiAddress,
    apiAppKey,
    setApiAppKey,
    bearerToken,
    setBearerToken,

  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
